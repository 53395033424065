import TablePage from '../../../../../components/table_page';
import Form from '../form';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {};
  },

  created() {
    this.getConfigList('product_price_maintain_list');
  },
  methods: {
    modalClick({ val, row }) {
      const params = this.selectRow.map((item) => item.mdmCustomerPriceId);
      console.log(val.code, row, 999);
      if (val.code === 'edit') {
        this.formConfig = { ...val, row, code: val.code };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'add') {
        this.formConfig = { ...val, code: val.code };
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'view') {
        this.formConfig = { ...val, code: val.code, row };
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'enable') {
        if (this.selection.length > 0) {
          this.$confirm('是否确定要启用？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request.post('/mdm/customerPriceController/enable', params).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      } else if (val.code === 'disable') {
        if (this.selection.length > 0) {
          this.$confirm('是否确定要禁用？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request.post('/mdm/customerPriceController/disable', params).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
      return true;
    },
    // 删除
    deleteClick({ val }) {
      const params = this.selectRow.map((item) => item.mdmCustomerPriceId);
      if (val.code === 'delete') {
        if (this.selection.length > 0) {
          this.$confirm('是否确定要删除？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request.post('/mdm/customerPriceController/delete', params).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
    },
  },
};
