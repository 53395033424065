<template>
  <div class="custom-select">
    <div class="cost-select" @click="openModal">
      <el-input :placeholder="title" v-model="value" :disabled="disabled" :readonly="true" />
      <div class="clear-icon" v-if="value && !disabled">
        <i class="el-icon-circle-close" @click="clearFn"></i>
      </div>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>
<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'CustomerList',
  props: {
    title: String,
    value: String,
    disabled: Boolean,
    getValue: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // state: '',
    };
  },
  components: {
    SelectConfig,
  },
  created() {
    // this.getAuthBudget();
  },
  methods: {

    // 新增弹窗
    openModal() {
      const params = { ...this.params };
      if (this.disabled || Object.keys(this.params).length === 0) {
        return;
      }

      if (params.idKey) {
        params.data = this.value ? [{
          [`${params.idKey}`]: this.value,
        }] : [];
      } else {
        params.noReset = true;
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择回调
    onGetSelect(val) {
      this.$emit('change', val);
    },
    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
  },
};
</script>

<style lang="less" scoped>
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
