var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "terminalInfo-contant-box" }, [
        _c(
          "div",
          { staticClass: "terminalInfo-supplys-btn-group" },
          [
            _vm.code === "add"
              ? _c(
                  "span",
                  { staticClass: "terminalInfo-supplys-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-plus", type: "text" },
                        on: { click: _vm.openModal },
                      },
                      [_vm._v("添加产品")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("TableList", {
              staticStyle: { marginTop: "10px" },
              attrs: { code: _vm.code, data: _vm.productData },
              on: { deleteItem: _vm.deleteItem },
            }),
          ],
          1
        ),
      ]),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }