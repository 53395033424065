<template>
  <div>
    <div class="terminalInfo-contant-box">
      <div class="terminalInfo-supplys-btn-group">
        <span v-if="code ==='add'" class="terminalInfo-supplys-btn">
          <el-button icon="el-icon-plus" type="text" @click="openModal">添加产品</el-button>
        </span>
        <TableList :code="code" style="marginTop: 10px" :data="productData" @deleteItem="deleteItem" />
      </div>
    </div>
   <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>

import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import TableList from './table/table.vue';

export default {
  components: {
    TableList,
    SelectConfig,
  },
  props: {
    value: Array,
    code: {
      type: String,
      default: '',
    },
  },
  name: 'ProductInfo',
  data() {
    return {
      productData: [],
    };
  },
  watch: {
    value(newVal) {
      this.productData = newVal || [];
    },
  },

  created() {

  },
  methods: {
    // 删除
    deleteItem(index) {
      this.productData.splice(index, 1);
      this.$emit('input', this.productData);
    },

    // 打开弹窗
    openModal() {
      const params = {
        functionCode: 'product_list',
        data: [],
        idKey: 'productCode',
        paramData: {},
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 选择数据
    onGetSelect(value) {
      this.productData = value;
      this.$emit('input', this.productData);
    },
    // 提交
    submitData() {
      this.$emit('input', this.productData);
    },
  },
};
</script>

<style lang = "less" scoped>
/deep/.el-button--small.is-circle {
  padding: 5px;
  margin-right: 8px;
}
/deep/.terminalInfo-contact-item-title {
  padding: 0px 10px;
  text-align: right;
}
.supplys-title {
  /* border-top: 1px solid #aeaeae; */
  /* padding-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  .addBtn {
    cursor: pointer;
    color: #409eff;
    margin-left: 5px;
    font-size: 16px;
  }
}
.terminalInfo-contant-box {
  margin-bottom: 19px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  .terminalInfo-contant {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    .terminalInfo-contact-item {
      /* flex:1; */
      display: flex;
      width: 33.33%;
      margin-bottom: 18px;
      .terminalInfo-contact-item-title {
        width: 125px;
        color: #606266;
      }
    }
  }
  .terminalInfo-contant-delete {
    width: 5%;
    i {
      color: #409eff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .terminalInfo-supplys-btn-group {
    width: 100%;
    .terminalInfo-supplys-btn {
      color: #1c92ff;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
