import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import ProductInfo from '../components/product';
import CustomerList from '../components/customer_list';

formCreate.component('ProductInfo', ProductInfo);
formCreate.component('CustomerList', CustomerList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'product_price_form',
    };
  },

  async created() {
    if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
      await this.init();
    }
  },
  methods: {
    //

    // 表单渲染完成后回调
    formComplete() {
      const productLevelName = this.getRule('customerName');
      productLevelName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
          });
        }
      };
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'customerName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'mdmCustomerPriceDetailReqVo') {
        v.props = {
          ...v.props,
          code: this.formConfig.code,
        };
        v.on = {
          change: (e) => {
            this.setValue({
              mdmCustomerPriceDetailReqVo: e,
            });
          },
        };
      }
      return v;
    },

    saveRef(ref) {
      this.productRef = ref;
    },

    // 改变时间
    timeChange(val) {
      if (val && val.length > 0) {
        this.setValue({
          startDate: val[0],
          endDate: val[1],
        });
      } else {
        this.setValue({
          startDate: null,
          endDate: null,
        });
      }
    },

    // 提交
    async submit() {
      const params = this.getFormData();
      if (!params) return false;

      let url = '/mdm/customerPriceController/save';

      const { row, code } = this.formConfig;

      if (!params.mdmCustomerPriceDetailReqVo || params.mdmCustomerPriceDetailReqVo.length === 0) {
        this.$message({
          type: 'error',
          message: '请填写活动明细',
        });
        return false;
      }
      const tempArr = [];
      params.mdmCustomerPriceDetailReqVo.forEach((data) => {
        if (code === 'add') {
          tempArr.push({
            id: null,
            materialCode: data.materialCode,
            materialName: data.materialName,
          });
        } else {
          tempArr.push({
            id: data.id,
            delFlag: data.delFlag,
            directlyPriceCode: data.directlyPriceCode,
            enableStatus: data.enableStatus,
            materialCode: data.materialCode,
            materialName: data.materialName,
            price: data.price,
          });
        }
      });
      params.mdmCustomerPriceDetailReqVo = tempArr;
      delete params.title1;

      if (new Date(params.startDate) > new Date(params.endDate)) {
        this.$message.error('终端价格结束时间不能小于开始时间');
        return;
      }
      if (new Date(params.groupStartDate) > new Date(params.groupEndDate)) {
        this.$message.error('团购价格结束时间不能小于开始时间');
        return;
      }

      if (code === 'edit') {
        url = '/mdm/customerPriceController/update';
        params.id = row.id;
      }
      const { success } = await request.post(url, params);
      if (success) {
        this.$message({
          type: 'success',
          message: '保存成功',
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },

    async init() {
      const data = await request.post('/mdm/customerPriceController/queryDetail', { id: this.formConfig.row.mdmCustomerPriceId }).then((res) => (res.success ? res.result : []));
      // 处理时间
      if (data.startDate && data.endDate) {
        data.effectiveTime = [`${data.startDate}`, `${data.endDate}`];
      }
      this.setValue(data);
      const mdmCustomerPriceDetailReqVo = this.getRule('mdmCustomerPriceDetailReqVo');
      mdmCustomerPriceDetailReqVo.props = {
        code: this.formConfig.code,
        ...mdmCustomerPriceDetailReqVo.props,
        value: data.mdmCustomerPriceDetailReqVo,
      };

      const resultCount = await request.post('/sfa/sfaWorkDailyController/queryOrderItemDetail', {
        customerCode: data.customerCode,
        startDate: data.startDate,
        endDate: data.endDate,
        materialCode: data.mdmCustomerPriceDetailReqVo[0].materialCode,
      });
      if (resultCount.success) {
        if (resultCount.result > 0) {
          this.disabled(true, ['price', 'startDate', 'groupPrice', 'groupStartDate']);
        }
      }

      this.$forceUpdate();
    },
  },
};
